import React from 'react'
import { Outlet } from 'react-router-dom'

function FindHomeDetails() {
    return (
        <>
            <Outlet />
        </>
    )
}

export default FindHomeDetails 